import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import packageJson from '../package.json';
import axiosRetry from 'axios-retry';
import { useTranslation } from 'react-i18next';
import './app.scss';

axiosRetry(axios, {
	retries: Infinity, // number of retries
	retryDelay: (retryCount) => {
		return retryCount * 10000; // time interval between retries
	}
});

const MainUI = React.lazy(() =>
	import('./MainUI').then(({ MainUI }) => ({ default: MainUI }))
);
const ErrorPage = React.lazy(() =>
	import('./pages/Error/ErrorPage').then(module => ({ default: module.ErrorPage }))
);
const carImg = require('./resources/car.png') as string;


function isVersion(pathName: string) {
	return pathName === "/version";
}

/* The main function of the app. */
function App() {
	const [itineraryId, setItineraryId] = useState<number>(-1);
	const [subRallyId, setSubRallyId] = useState<number | null>(null);
	const { t } = useTranslation();

	// API'S
	// race, chronos sections_scorings -> intermediates times
	const [race, setRace] = useState<Race>();
	const [showRace, setShowRace] = useState(false);

	const [specials, setSpecials] = useState(null);
	const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);

	const queryParameters = new URLSearchParams(window.location.search);
	let rallyId: number = -1

	const rallyIdString = queryParameters.get("rallyId");
	if (rallyIdString !== null) {
		rallyId = parseInt(rallyIdString);
	}

	if (errorMessage === null && rallyId === -1) {
		if (isVersion(window.location.pathname)) {
			setErrorMessage(<p>Version: {packageJson.version}</p>)
		} else {
			setErrorMessage(<p>No rally has been specified. Please specify a valid URL parameter value for <b>rallyId</b>.</p>)
		}
	}

	/* A function that is called every 5 seconds to update the data from the backend. */
	const updateResources = useCallback(async () => {
		if (rallyId === -1) {
			return;
		}

		let respItineraryId = null;

		/* Get itineraries from API 'itineraries' */
		await (async () => {
			let response;

			try {
				response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/timing/api/itineraries/${rallyId}.json`);
			} catch (e) {
				setErrorMessage(<p>There was an error fetching itinerary data from the backend server.</p>);
				return;
			}

			respItineraryId = response.data.event.data[0]?.id;
			setItineraryId(respItineraryId);
		})()

		/* Get specials from API 'specials' */
		if (respItineraryId !== null) {
			(async () => {
				let response;

				try {
					response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/timing/api/specials/${rallyId}.json?itinerary_id=${respItineraryId}`);
				} catch (e) {
					setErrorMessage(<p>There was an error fetching specials data from the backend server.</p>);
					return;
				}

				setSpecials(response.data.event.data);
			})();
		}

		/* Get race data from API 'race' */
		if (respItineraryId !== null) {
			(async () => {
				let response;

				try {
					response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/timing/api/race/${rallyId}.json`);
				} catch (e) {
					setErrorMessage(<p>There was an error fetching specials data from the backend server.</p>);
					return;
				}

				setRace(response.data.event.data);
				setShowRace(response.data.event.data.active)
				setSubRallyId(response.data.event.data.subrally_id);
			})();
		}

	}, [rallyId]);

	useEffect(() => {
		updateResources();

		const interval = setInterval(() => {
			updateResources();
		}, 10000);

		return () => clearInterval(interval);
	}, [updateResources]);

	if (errorMessage !== null) {
		return (
			<React.Suspense fallback={<></>}>
				<ErrorPage
					errorPage={errorMessage}
					windowTitle={"Error"}
				/>
			</React.Suspense>
		);
	}

	if (!specials) {
		return (
			<div className='loading'>
				<div className="loading-car">
					<img src={carImg} alt='car-loading' />
				</div>
				<div>{t('noData.loading').toUpperCase()}</div>
			</div>
		)
	}

	// if (!showRace) {
	// 	return (<div>The rally is inactive</div>)
	// }

	// FIXME: quick hack to avoid error if race is not ready
	if (!race) {
		return (<div></div>)
	}

	return (
		<React.Suspense fallback={<></>}>
			<MainUI
				rallyId={rallyId}
				specials={specials}
				itineraryId={itineraryId}
				race={race}
				subRallyId={subRallyId}
			/>
		</React.Suspense>
	);
}

export default App;